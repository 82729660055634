import CustomCard from "../../components/reusable/CustomCard";
import Memo from "../../components/reusable/Memo";

import classes from "./FuelSources.module.css";

type Props = {
  data: any;
  isRestoreReport: boolean;
};

const FuelSources = ({ data, isRestoreReport }: Props) => {
  return (
    <div className={classes.section3}>
      <div className={classes.chart}>
        <CustomCard>
          <div className={classes.title}>{data.FUEL_SOURCES_TITLE}</div>
          <div className={classes.description}>
            {data.FUEL_SOURCES_DESCRIPTION}
          </div>
          <div>
            <div className={classes.inner}>
              <div className={classes.container}>
                <div
                  className={classes.fill}
                  style={{ width: `${data.FUEL_SOURCES_CARBS}%` }}
                ></div>
              </div>
            </div>
            <div className={classes.labels}>
              <div className={classes.point0}>0</div>
              <div className={classes.point25}>25</div>
              <div className={classes.point50}>50</div>
              <div className={classes.point75}>75</div>
              <div className={classes.point100}>100</div>
            </div>
          </div>
        </CustomCard>
      </div>
      <div className={classes.legend}>
        <CustomCard>
          <Memo
            text={data.FUEL_SOURCES_LABEL1}
            color="var(--secondary12)"
            size="11.98px"
            fontFamily="AktivGroteskEx_A_Md"
          />
          <Memo
            text={data.FUEL_SOURCES_LABEL2}
            color="var(--secondary22)"
            size="11.98px"
            fontFamily="AktivGroteskEx_A_Md"
          />
          <div className={classes.description}>
            {data.FUEL_SOURCES_DESCRRIPTION2}
          </div>
        </CustomCard>
        {isRestoreReport && (
          <CustomCard cardMargin={"16px 0 0 0"}>
            <div
              style={{
                textAlign: "center",
                fontFamily: "AktivGroteskEx_A_Md",
                color: "var(--primary1000)",
              }}
            >{`${data.RER_LABEL}=${data.RER}`}</div>
          </CustomCard>
        )}
      </div>
    </div>
  );
};

export default FuelSources;
