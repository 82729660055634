import { useEffect, useState } from "react";

import CustomCard from "../../components/reusable/CustomCard";
import Layout from "../../components/reusable/Layout";
import Title from "../../components/reusable/Title";
import TestItem from "./TestItem";

import classes from "./TestingSchedulePage.module.css";
import RestoreCustomWellnessPlan from "../../components/RestoreCustomWellnessPlan";

type TestType = { INDEX: number; VALUE: string };

type Dates = {
  INDEX: number;
  PERIOD: string | null;
  TITLE: string;
  TYPE: TestType[];
};

interface Props {
  data: { DATES: Dates[]; DESCRIPTION: string; INDEX: number; TITLE: string };
  onSvgConverter: (
    content: string,
    result: string,
    imgWidth: number
  ) => JSX.Element;
  isRestoreReport: boolean;
}
const TestingSchedulePage = ({
  data,
  onSvgConverter,
  isRestoreReport,
}: Props) => {
  const [imgLoad, setImgLoad] = useState(false);

  useEffect(() => {
    onSvgConverter("testItem", "resultTestItem", 685);
    setImgLoad(true);
  }, [onSvgConverter]);

  let imgTestItem = (
    <div id="testItem">
      <ul className={classes.innerContainer}>
        {data.DATES.map((item) => (
          <li className={classes.testItem} key={item.INDEX}>
            <TestItem item={item} />
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <Layout>
      <Title title={data.TITLE} />
      <CustomCard cardMargin="60px auto">
        <div id="resultTestItem" className={classes.imgRadarChart}>
          {!imgLoad && imgTestItem}
        </div>
      </CustomCard>
      {isRestoreReport ? (
        <RestoreCustomWellnessPlan />
      ) : (
        <CustomCard cardMargin="-20px auto" cardHeight={200}>
          <div className={classes.desctiption}>{data.DESCRIPTION}</div>
        </CustomCard>
      )}
    </Layout>
  );
};

export default TestingSchedulePage;
