import Layout from "../../components/reusable/Layout";
import Pillar from "./Pillar";

import classes from "./PillarsOfLongevityPage.module.css";

import longevityMale from "../../images/pillars/longevityMale.png";
import longevityFemale from "../../images/pillars/longevityFemale.png";
import weightLossMale from "../../images/pillars/weightLossMale.png";
import weightLossFemale from "../../images/pillars/weightLossFemale.png";
import performanceMale from "../../images/pillars/performanceMale.png";
import performanceFemale from "../../images/pillars/performanceFemale.png";
import restoreUnisex from "../../images/pillars/restoreUnisex.png";

type PillarProps = {
  DESCRIPTION: string;
  ICON: string;
  INDEX: number;
  TITLE: string;
};

interface Props {
  data: {
    DESCRIPTION: string;
    INDEX: number;
    PILLARS: PillarProps[];
    TITLE: string;
  };
  gender: string;
  reportType: string;
  isRestoreReport: boolean;
}

const PillarsOfLongevityPage = ({
  data,
  gender,
  reportType,
  isRestoreReport,
}: Props): JSX.Element => {
  let addedReportsPillars;
  // SD-3924 Remove "Gut" pillar from performance report type
  // if (reportType === "14" || reportType === "15") {
  //   addedReportsPillars = (
  //     <div className={classes.pillarsSection}>
  //       {data.PILLARS.slice(4).map((pillar) => (
  //         <Pillar key={pillar.INDEX} data={pillar} />
  //       ))}
  //     </div>
  //   );
  // } else {
  addedReportsPillars = (
    <div className={classes.pillarsSectionCenter}>
      {data.PILLARS.slice(4, -1).map((pillar) => (
        <Pillar key={pillar.INDEX} data={pillar} />
      ))}
    </div>
  );
  // }
  const backgroundImgHandler = (type: string, gender: string): string => {
    let backgroundImg: string;

    switch (type) {
      case "restore":
        backgroundImg = restoreUnisex;
        break;
      case "10":
      case "11":
        backgroundImg = gender === "Female" ? longevityFemale : longevityMale;
        break;
      case "12":
      case "13":
        backgroundImg = gender === "Female" ? weightLossFemale : weightLossMale;
        break;
      case "14":
      case "15":
        backgroundImg =
          gender === "Female" ? performanceFemale : performanceMale;
        break;
      default:
        backgroundImg = longevityMale;
    }
    return backgroundImg;
  };

  return (
    <Layout customPadding={0}>
      <img
        className={classes.backgroundImg}
        src={backgroundImgHandler(
          isRestoreReport ? "restore" : reportType,
          gender
        )}
        alt="background img per category"
      />
      <div className={classes.header}>
        <div
          className={classes.title}
          style={{
            color: isRestoreReport ? "var(--primary100)" : "var(--primary1000)",
          }}
        >
          {data.TITLE}
        </div>
      </div>
      <div className={classes.pillarsSection}>
        {data.PILLARS.slice(0, 4).map((pillar) => (
          <Pillar key={pillar.INDEX} data={pillar} />
        ))}
      </div>
      {addedReportsPillars}
    </Layout>
  );
};

export default PillarsOfLongevityPage;
