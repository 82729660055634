import { useEffect, useState } from "react";

import Layout from "../../components/reusable/Layout";
import Title from "../../components/reusable/Title";
import RiskChartBar from "../../components/reusable/RiskChartBar";
import CardDescription from "../../components/reusable/CardDescription";
import SuggestionCard from "../../components/SuggestionCard";
import Qrcode from "../../components/reusable/Qrcode";

import classes from "./RiskMetricPage.module.css";
import CustomSuggestionCard from "../../components/CustomSuggestionCard";

type Tab = { INDEX: number; NAME: string; VALUE: number };

type improveArea = { INDEX: number; TEXT: string; TITLE: string };

type Metric = {
  HOW_IT_IS_MEASURED: string;
  HOW_IT_IS_MEASURED_TEXT: string;
  INDEX: number;
  SUGGESTIONS: { IMPROVE_AREAS: improveArea[]; INDEX: number; TITLE: string }[];
  TITLE1: string;
  TITLE2: string;
  TOP: string;
  VALUE: number;
  WHAT_IT_IS: string;
  WHAT_IT_IS_TEXT: string;
  WHY_ITS_IMPORTANT: string;
  WHY_ITS_IMPORTANT_TEXT: string;
  RECCOMENDATIONS_TO_IMPROVE: string;

  SOURCES?: any;
};

interface Props {
  data: {
    DESCRIPTION: string;
    INDEX: number;
    METRICS: Metric[];
    NAVBAR: Tab[];
    RISK_METRICS: Metric[];
    PERFORMANCE_METRICS: Metric[];
    QRCODE: {
      FOOTER1: string;
      HEADER1: string;
      HEADER2: string;
      QRCODE: string;
    };
    TITLE: string;
    TITLE_PERFORMANCE_METRICS: string;
  };
  onSvgConverter: (
    content: string,
    result: string,
    imgWidth: number
  ) => JSX.Element;
}

const RiskMetricPage = ({ data, onSvgConverter }: Props): JSX.Element => {
  const [imgRiskBar, setImgRiskBar] = useState(false);

  useEffect(() => {
    onSvgConverter("metricPageChart", "imgMetricPageChart", 750);
    setImgRiskBar(true);
  }, [onSvgConverter]);

  const riskBar = (
    <div id="metricPageChart" className={classes.chart}>
      <RiskChartBar
        data={data.RISK_METRICS[0]}
        color="var(--primary50)"
        onSvgConverter={onSvgConverter}
      />
    </div>
  );

  return (
    <Layout>
      <Title title={data.RISK_METRICS[0].TITLE1} />
      <div id="imgMetricPageChart" className={classes.imgMetricPageChart}>
        {!imgRiskBar && riskBar}
      </div>

      <ul className={classes.inner}>
        <li className={classes.sub}>
          <CardDescription
            title={data.RISK_METRICS[0].WHAT_IT_IS}
            description={data.RISK_METRICS[0].WHAT_IT_IS_TEXT}
            useLargerFont={true}
            index={data.RISK_METRICS[0].INDEX}
          />
        </li>
        <li className={classes.sub}>
          <CardDescription
            title={data.RISK_METRICS[0].HOW_IT_IS_MEASURED}
            description={data.RISK_METRICS[0].HOW_IT_IS_MEASURED_TEXT}
            useLargerFont={true}
            index={data.RISK_METRICS[0].INDEX}
          />
        </li>
      </ul>
      <div className={classes.card_container}>
        <div className={classes.suggestion_tables_title}>
          {data.RISK_METRICS[0].RECCOMENDATIONS_TO_IMPROVE}
        </div>
        <ul className={classes.suggestion_tables}>
          {data.RISK_METRICS[0].SUGGESTIONS.map((item, index: number) =>
            item.INDEX > 3 ? (
              <CustomSuggestionCard key={index} data={item} />
            ) : (
              <SuggestionCard key={index} data={item} />
            )
          )}
        </ul>
      </div>
      <ul className={classes.bottom}>
        <li className={classes.sub}>
          <CardDescription
            title={data.RISK_METRICS[0].WHY_ITS_IMPORTANT}
            description={data.RISK_METRICS[0].WHY_ITS_IMPORTANT_TEXT}
            useLargerFont={true}
            index={data.RISK_METRICS[0].INDEX}
          />
        </li>
      </ul>

      <Qrcode data={data.QRCODE} sources={data.RISK_METRICS[0].SOURCES} />
    </Layout>
  );
};

export default RiskMetricPage;
